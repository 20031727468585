
.OTSubscriberContainer {
  width: 100% !important;
  height: 100vh !important;
}

.OTPublisherContainer {
  width: 15rem !important;
  height: 15rem !important;
}

.fc-bg-event {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #68c7c5 !important;
  border: 0.5px dashed black;
}

.fc-event-main {
  overflow: hidden;
}